import React, { useState, useEffect } from 'react';
import { api } from '../services/api';
import './OrderForm.css';

const OrderForm = ({ listing, onClose }) => {
  const [step, setStep] = useState(1); // 1: quantity, 2: checkout info
  const [quantity, setQuantity] = useState(listing.minimum_order);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderInfo, setOrderInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    calculateTotal(quantity);
  }, [quantity]);

  const calculateTotal = (qty) => {
    let price = listing.price;
    let totalPrice = price * qty;
    
    // Find the highest applicable discount
    if (listing.bulkDiscounts) {
      const applicableDiscount = [...listing.bulkDiscounts]
        .sort((a, b) => b.quantity - a.quantity) // Sort by quantity descending
        .find(discount => qty >= discount.quantity);

      if (applicableDiscount) {
        // Apply discount to entire order
        const discountAmount = totalPrice * (applicableDiscount.discount_percentage / 100);
        totalPrice = totalPrice - discountAmount;
      }
    }

    setTotalPrice(totalPrice);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setError('');

    if (newQuantity < listing.minimum_order) {
      setError(`Minimum order quantity is ${listing.minimum_order} ${listing.unit}`);
    } else if (newQuantity > listing.quantity) {
      setError(`Maximum available quantity is ${listing.quantity} ${listing.unit}`);
    } else {
      setQuantity(newQuantity);
    }
  };


  const formatCardNumber = (value) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
  
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
  
    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  };
  
  const formatExpiryDate = (value) => {
    const v = value.replace(/\D/g, '');
    let month = v.substring(0, 2);
    const year = v.substring(2, 4);
  
    if (month.length === 1 && month[0] > 1) {
      month = `0${month}`;
    }
    if (month.length === 2) {
      if (Number(month) === 0) {
        month = '01';
      }
      if (Number(month) > 12) {
        month = '12';
      }
    }
  
    return month + (year.length ? '/' + year : '');
  };


    const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'cardNumber') {
        formattedValue = formatCardNumber(value);
    } else if (name === 'expiryDate') {
        formattedValue = formatExpiryDate(value);
    }

    setOrderInfo(prev => ({
        ...prev,
        [name]: formattedValue
    }));
    };

  const validateCardNumber = (number) => {
    return number.replace(/\s/g, '').length === 16;
  };

  const validateForm = () => {
    if (!orderInfo.fullName) return "Full name is required";
    if (!orderInfo.email.includes('@')) return "Valid email is required";
    if (!orderInfo.phone) return "Phone number is required";
    if (!orderInfo.address) return "Address is required";
    if (!orderInfo.city) return "City is required";
    if (!orderInfo.state) return "State is required";
    if (!orderInfo.zipCode) return "ZIP code is required";
    if (!validateCardNumber(orderInfo.cardNumber)) return "Valid card number is required";
    if (!orderInfo.expiryDate.match(/^\d{2}\/\d{2}$/)) return "Valid expiry date (MM/YY) is required";
    if (!orderInfo.cvv.match(/^\d{3}$/)) return "Valid CVV is required";
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (step === 1) {
      if (!error) {
        setStep(2);
      }
      return;
    }
  
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
  
    try {
      const orderData = {
        listingId: listing.id,
        quantity: quantity,
        totalPrice: totalPrice,
        shippingName: orderInfo.fullName,
        shippingEmail: orderInfo.email,
        shippingPhone: orderInfo.phone,
        shippingAddress: orderInfo.address,
        shippingCity: orderInfo.city,
        shippingState: orderInfo.state,
        shippingZip: orderInfo.zipCode,
        // Add this flag to indicate guest checkout
        isGuestCheckout: !api.getToken()
      };
  
      await api.createOrder(orderData);
      onClose();
      alert('Order placed successfully! A confirmation email will be sent to ' + orderInfo.email);
    } catch (error) {
      setError(error.message || 'Error placing order');
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  return (
    <div className="order-form-overlay">
      <div className="order-form">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>{step === 1 ? 'Order Quantity' : 'Checkout Information'}</h2>
        
        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit}>
          {step === 1 ? (
            <div className="quantity-section">
              <div className="form-group">
                <label>Quantity ({listing.unit})</label>
                <input
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                  min={listing.minimum_order}
                  max={listing.quantity}
                  required
                />
              </div>

              {listing.bulkDiscounts?.length > 0 && (
                <div className="bulk-discounts-info">
                    <h4>Bulk Discounts Available:</h4>
                    {listing.bulkDiscounts.map((discount, index) => (
                    <div key={index} className="discount-info">
                        Order {discount.quantity} {listing.unit} or more: {discount.discount_percentage}% off entire order
                    </div>
                    ))}
                </div>
                )}

                <div className="order-summary">
                <div className="price-breakdown">
                    <span>Base Price per {listing.unit}:</span>
                    <span>{formatPrice(listing.price)}</span>
                </div>
                <div className="price-breakdown">
                    <span>Quantity:</span>
                    <span>{quantity} {listing.unit}</span>
                </div>
                {listing.bulkDiscounts?.length > 0 && 
                    quantity >= Math.min(...listing.bulkDiscounts.map(d => d.quantity)) && (
                    <div className="price-breakdown discount-applied">
                    <span>Bulk Discount Applied:</span>
                    <span>-{Math.max(...listing.bulkDiscounts
                        .filter(d => quantity >= d.quantity)
                        .map(d => d.discount_percentage))}%</span>
                    </div>
                )}
                <div className="total-price">
                    <span>Total:</span>
                    <span>{formatPrice(totalPrice)}</span>
                </div>
                </div>
            </div>
          ) : (
            <div className="checkout-section">
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={orderInfo.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              
              <div className="form-row">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={orderInfo.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={orderInfo.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={orderInfo.address}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={orderInfo.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>State</label>
                  <input
                    type="text"
                    name="state"
                    value={orderInfo.state}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>ZIP Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={orderInfo.zipCode}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="payment-section">
                <h3>Payment Information</h3>
                <div className="form-group">
                    <label>Card Number</label>
                    <input
                        type="text"
                        name="cardNumber"
                        value={orderInfo.cardNumber}
                        onChange={handleInputChange}
                        placeholder="1234 5678 9012 3456"
                        maxLength="19" // 16 digits + 3 spaces
                        required
                    />
                    </div>

                <div className="form-row">
                <div className="form-group">
                    <label>Expiry Date</label>
                    <input
                    type="text"
                    name="expiryDate"
                    value={orderInfo.expiryDate}
                    onChange={handleInputChange}
                    placeholder="MM/YY"
                    maxLength="5"
                    required
                    />
                </div>
                  <div className="form-group">
                    <label>CVV</label>
                    <input
                      type="text"
                      name="cvv"
                      value={orderInfo.cvv}
                      onChange={handleInputChange}
                      maxLength="3"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="order-summary">
                <div className="total-price">
                  <span>Total Amount:</span>
                  <span>{formatPrice(totalPrice)}</span>
                </div>
              </div>
            </div>
          )}

          <div className="form-actions">
            {step === 2 && (
              <button 
                type="button" 
                className="btn btn-outline"
                onClick={() => setStep(1)}
              >
                Back
              </button>
            )}
            <button 
            type="submit" 
            className="btn btn-primary"
            disabled={isSubmitting}
            >
            {isSubmitting 
                ? 'Processing...' 
                : (step === 1 ? 'Continue to Checkout' : 'Place Order')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderForm;