import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, BASE_URL } from '../services/api';
import './OrderHistory.css';

const OrderHistory = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [sellerOrders, setSellerOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('purchases');
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await api.verifyToken();
        setCurrentUser(user);
        if (user) {
          fetchOrders();
          if (user.is_seller) {
            fetchSellerOrders();
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  const fetchOrders = async () => {
    try {
      const data = await api.getOrders();
      setOrders(data);
    } catch (error) {
      setError('Error fetching orders: ' + error.message);
    }
  };

  const fetchSellerOrders = async () => {
    try {
      const data = await api.getSellerOrders();
      setSellerOrders(data);
    } catch (error) {
      setError('Error fetching seller orders: ' + error.message);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="order-history-page">
      <div className="order-history-container">
        <div className="page-header">
          <button className="back-button" onClick={() => navigate('/')}>
            ← Back to Listings
          </button>
          <h1>Order History</h1>
        </div>

        {currentUser?.is_seller && (
          <div className="tabs">
            <button 
              className={`tab ${activeTab === 'purchases' ? 'active' : ''}`}
              onClick={() => setActiveTab('purchases')}
            >
              My Purchases
            </button>
            <button 
              className={`tab ${activeTab === 'sales' ? 'active' : ''}`}
              onClick={() => setActiveTab('sales')}
            >
              My Sales
            </button>
          </div>
        )}

        <div className="orders-list">
          {(!currentUser?.is_seller || activeTab === 'purchases') ? (
            orders.length > 0 ? (
              orders.map(order => (
                <div key={order.id} className="order-card">
                  <div className="order-header">
                    <div className="order-date">
                      Ordered on {new Date(order.created_at).toLocaleDateString()}
                    </div>
                    <div className="order-status">{order.status}</div>
                  </div>
                  
                  <div className="order-details">
                    <img 
                      src={`${BASE_URL}${order.image_url}`}
                      alt={order.title}
                      className="order-image"
                    />
                    <div className="order-info">
                      <h3>{order.title}</h3>
                      <p>Quantity: {order.quantity} {order.unit}</p>
                      <p>Seller: {order.seller_name}</p>
                      <p>Total: {formatPrice(order.total_price)}</p>
                    </div>
                  </div>

                  <div className="shipping-info">
                    <h4>Shipping Details</h4>
                    <p>{order.shipping_name}</p>
                    <p>{order.shipping_address}</p>
                    <p>{order.shipping_city}, {order.shipping_state} {order.shipping_zip}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-orders">
                <p>No purchase history found.</p>
              </div>
            )
          ) : (
            sellerOrders.length > 0 ? (
              sellerOrders.map(order => (
                <div key={order.id} className="order-card">
                  <div className="order-header">
                    <div className="order-date">
                      Ordered on {new Date(order.created_at).toLocaleDateString()}
                    </div>
                    <div className="order-status">{order.status}</div>
                  </div>
                  
                  <div className="order-details">
                    <img 
                      src={`${BASE_URL}${order.image_url}`}
                      alt={order.title}
                      className="order-image"
                    />
                    <div className="order-info">
                      <h3>{order.title}</h3>
                      <p>Quantity: {order.quantity} {order.unit}</p>
                      <p>Buyer: {order.buyer_name}</p>
                      <p>Total: {formatPrice(order.total_price)}</p>
                    </div>
                  </div>
            
                  <div className="shipping-contact-info">
                    <div className="shipping-details">
                      <h4>Shipping Details</h4>
                      <p>{order.shipping_name}</p>
                      <p>{order.shipping_address}</p>
                      <p>{order.shipping_city}, {order.shipping_state} {order.shipping_zip}</p>
                    </div>
                    <div className="contact-details">
                      <h4>Contact Information</h4>
                      <p><strong>Email:</strong> {order.shipping_email}</p>
                      <p><strong>Phone:</strong> {order.shipping_phone}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-orders">
                <p>No sales history found.</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;