import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import CreateListing from './CreateListing';

const EditListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const data = await api.getListing(id);
        
        // Check if the listing actually has valid specifications
        const hasValidSpecs = Boolean(
          data.specifications && 
          data.specifications[0]?.headers?.length > 0 && 
          data.specifications[0]?.rows?.some(row => row.cells.some(cell => cell))  // Check if any cells have content
        );

        const formattedData = {
          title: data.title,
          description: data.description,
          price: data.price,
          quantity: data.quantity,
          minimumOrder: data.minimum_order,
          unit: data.unit,
          location: data.location,
          category: data.category,
          image: null,
          // Only set includeSpecs true if we have valid specs
          includeSpecs: hasValidSpecs,
          // If we have valid specs, use them; otherwise use default empty template
          specifications: hasValidSpecs ? data.specifications : [{
            headers: ['Property', 'Value', 'Unit'],
            rows: [{ cells: ['', '', ''], bold: [false, false, false] }]
          }],
          bulkDiscounts: data.bulkDiscounts?.map(d => ({
            quantity: d.quantity,
            discount: d.discount_percentage
          })) || [{ quantity: '', discount: '' }],
          existingImageUrl: data.image_url
        };
        setListing(formattedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchListing();
  }, [id]);

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!listing) return <div className="error-message">Listing not found</div>;

  return (
    <CreateListing 
      isEditing={true}
      initialData={listing}
      listingId={id}
    />
  );
};

export default EditListing;