import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import './BecomeSeller.css';

const BecomeSeller = () => {
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreed) {
      setError('Please agree to the terms');
      return;
    }

    try {
      await api.becomeSeller();
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="become-seller-page">
      <div className="become-seller-card">
        <h1>Become a Seller</h1>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            />
            <label htmlFor="agree">
              I agree to the terms and conditions of becoming a seller
            </label>
          </div>
          <button 
            type="submit" 
            className="btn btn-primary"
            disabled={!agreed}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default BecomeSeller;