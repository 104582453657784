import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pencil, Trash2 } from 'lucide-react';
import { api, BASE_URL } from '../services/api';
import './MyListings.css';

const MyListings = () => {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null); // Store listing ID to delete

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await api.verifyToken();
        if (!user || !user.is_seller) {
          navigate('/');
          return;
        }
        setCurrentUser(user);
        fetchListings();
      } catch (err) {
        setError(err.message);
      }
    };
    checkAuth();
  }, [navigate]);

  const fetchListings = async () => {
    try {
      const data = await api.getSellerListings();
      setListings(data);
    } catch (err) {
      setError('Error fetching listings: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (listingId) => {
    try {
      await api.deleteListing(listingId);
      setListings(listings.filter(listing => listing.id !== listingId));
      setShowDeleteConfirm(null);
    } catch (err) {
      setError('Error deleting listing: ' + err.message);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="my-listings-page">
      <div className="my-listings-container">
        <div className="page-header">
          <button className="back-button" onClick={() => navigate('/')}>
            ← Back to Dashboard
          </button>
          <h1>My Listings</h1>
          <button 
            className="btn btn-primary create-listing-btn"
            onClick={() => navigate('/create-listing')}
          >
            Create New Listing
          </button>
        </div>

        <div className="listings-grid">
          {listings.length > 0 ? (
            listings.map(listing => (
              <div key={listing.id} className="listing-card">
                <div className="listing-image">
                  <img src={`${BASE_URL}${listing.image_url}`} alt={listing.title} />
                  {listing.quantity === 0 && (
                    <div className="out-of-stock-badge">
                      Out of Stock
                    </div>
                  )}
                </div>
                <div className="listing-content">
                  <h3 className="listing-title">{listing.title}</h3>
                  <div className="listing-price">
                    {formatPrice(listing.price)}/{listing.unit}
                  </div>
                  <div className="listing-details">
                    <span className="stock-info">
                      Stock: {listing.quantity} {listing.unit}
                    </span>
                    <span className="min-order">
                      Min Order: {listing.minimum_order} {listing.unit}
                    </span>
                  </div>
                  <div className="listing-actions">
                    <button 
                      className="btn btn-outline btn-icon"
                      onClick={() => navigate(`/edit-listing/${listing.id}`)}
                      title="Edit Listing"
                    >
                      <Pencil size={16} />
                      <span>Edit</span>
                    </button>
                    <button 
                      className="btn btn-danger btn-icon"
                      onClick={() => setShowDeleteConfirm(listing.id)}
                      title="Delete Listing"
                    >
                      <Trash2 size={16} />
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-listings">
              <p>You don't have any listings yet.</p>
              <button 
                className="btn btn-primary"
                onClick={() => navigate('/create-listing')}
              >
                Create Your First Listing
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this listing? This action cannot be undone.</p>
            <div className="modal-actions">
              <button 
                className="btn btn-outline"
                onClick={() => setShowDeleteConfirm(null)}
              >
                Cancel
              </button>
              <button 
                className="btn btn-danger"
                onClick={() => handleDelete(showDeleteConfirm)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyListings;