import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ListingCard.css';
import {  BASE_URL } from '../services/api';

const ListingCard = ({ listing }) => {
  const navigate = useNavigate();
  
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const getLowestDiscount = () => {
    if (!listing.bulkDiscounts?.length) return null;
    return Math.max(...listing.bulkDiscounts.map(d => d.discount_percentage));
  };

  return (
    <div className="listing-card" onClick={() => navigate(`/listing/${listing.id}`)}>
      <div className="listing-image">
        <img src={`${BASE_URL}${listing.image_url}`} alt={listing.title} />
        {listing.quantity === 0 && (
          <div className="out-of-stock-badge">
            Out of Stock
          </div>
        )}
        {listing.quantity > 0 && getLowestDiscount() && (
          <div className="discount-badge">
            Up to {getLowestDiscount()}% off
          </div>
        )}
      </div>
      <div className="listing-content">
        <h3 className="listing-title">{listing.title}</h3>
        <div className="listing-price">
          {formatPrice(listing.price)}/{listing.unit}
        </div>
        <div className="listing-details">
          <span className="location">{listing.location}</span>
          <span className="stock-info">
            {listing.quantity === 0 ? (
              <span className="out-of-stock">Out of Stock</span>
            ) : (
              <span>Stock: {listing.quantity} {listing.unit}</span>
            )}
          </span>
        </div>
        <p className="listing-description">
          {listing.description.length > 100 
            ? `${listing.description.substring(0, 100)}...` 
            : listing.description}
        </p>
        <div className="listing-footer">
          <span className="seller">By {listing.seller_name}</span>
          <span className="category">{listing.category}</span>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;