import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import BecomeSeller from './components/BecomeSeller';
import CreateListing from './components/CreateListing';
import OrderHistory from './components/OrderHistory';
import ListingDetail from './components/ListingDetail';
import EditListing from './components/EditListing';
import MyListings from './components/MyListings';
import './App.css';


function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/become-seller" element={<BecomeSeller />} />
          <Route path="/create-listing" element={<CreateListing />} />
          <Route path="/edit-listing/:id" element={<EditListing />} />
          <Route path="/listing/:id" element={<ListingDetail />} />
          <Route path="/orders" element={<OrderHistory />} /> {/* Add this route */}
          <Route path="/my-listings" element={<MyListings />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;