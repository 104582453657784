export const API_URL = 'https://backendws-production.up.railway.app/api';
export const BASE_URL ='https://backendws-production.up.railway.app';


export const api = {
  // Existing auth methods
  setToken: (token) => {
    localStorage.setItem('token', token);
  },

  getToken: () => {
    return localStorage.getItem('token');
  },

  removeToken: () => {
    localStorage.removeItem('token');
  },

  login: async (username, password) => {
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error);
      }
      
      api.setToken(data.token);
      return data;
    } catch (error) {
      throw error;
    }
  },
  
  register: async (username, password) => {
    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error);
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },

  verifyToken: async () => {
    const token = api.getToken();
    if (!token) return null;

    try {
      const response = await fetch(`${API_URL}/verify-token`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        api.removeToken();
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      api.removeToken();
      return null;
    }
  },

  becomeSeller: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/become-seller`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error);
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },

  // New listing methods
  createListing: async (formData) => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/listings`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
          // Note: Don't set Content-Type when sending FormData
        },
        body: formData
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error);
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },

  getListings: async () => {
    try {
      const response = await fetch(`${API_URL}/listings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) throw new Error('Error fetching listings');
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  updateListing: async (id, formData) => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/listings/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error);
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },

  deleteListing: async (id) => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/listings/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }
    } catch (error) {
      throw error;
    }
  },

  getNotifications: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/notifications`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error fetching notifications');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  markNotificationAsRead: async (notificationId) => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/notifications/${notificationId}/read`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error marking notification as read');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  markAllNotificationsAsRead: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/notifications/read-all`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error marking all notifications as read');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  getOrders: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/orders`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error fetching orders');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  getSellerOrders: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/seller/orders`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error fetching seller orders');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  createOrder: async (orderData) => {
    const token = api.getToken();
    
    try {
      const response = await fetch(`${API_URL}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {})
        },
        body: JSON.stringify(orderData)
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Error creating order');
      }
      
      return data;
    } catch (error) {
      console.error('Order creation error:', error);
      throw error;
    }
  },

  getSellerListings: async () => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');
  
    try {
      const response = await fetch(`${API_URL}/seller/listings`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Error fetching seller listings');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  // Optional: Add method to check order status
  getOrder: async (orderId) => {
    const token = api.getToken();
    if (!token) throw new Error('Not authenticated');

    try {
      const response = await fetch(`${API_URL}/orders/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Error fetching order');
      }
      
      return data;
    } catch (error) {
      throw error;
    }
  },


  getListing: async (id) => {
    try {
      const response = await fetch(`${API_URL}/listings/${id}`);
      
      if (!response.ok) {
        throw new Error('Error fetching listing');
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
};