import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api, BASE_URL } from '../services/api';
import OrderForm from './OrderForm';
import { Pencil, Trash2 } from 'lucide-react';
import './ListingDetail.css';

const ListingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await api.verifyToken();
      setCurrentUser(user);
    };
    checkAuth();
  }, []);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const data = await api.getListing(id);
        setListing(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchListing();
  }, [id]);

  const handleDelete = async () => {
    try {
      await api.deleteListing(id);
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const getImageUrl = (imagePath) => {
    return `${BASE_URL}${imagePath}`;
  };

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!listing) return <div className="error-message">Listing not found</div>;

  return (
    <div className="listing-detail-page">
      <div className="listing-detail-container">
        <div className="listing-header">
        <button className="back-button" onClick={() => navigate('/')}>
            ← Back to Listings
        </button>
        
        {currentUser && currentUser.id === listing.seller_id && (
            <div className="seller-controls">
            <button 
                className="btn btn-outline btn-icon"
                onClick={() => navigate(`/edit-listing/${id}`)}
                title="Edit Listing"
            >
                <Pencil size={18} />
                <span>Edit</span>
            </button>
            <button 
                className="btn btn-danger btn-icon"
                onClick={() => setShowDeleteConfirm(true)}
                title="Delete Listing"
            >
                <Trash2 size={18} />
                <span>Delete</span>
            </button>
            </div>
        )}
        </div>

        <div className="listing-detail-grid">
          <div className="listing-image-container">
            <img src={getImageUrl(listing.image_url)} alt={listing.title} />
          </div>

          <div className="listing-info">
            <h1>{listing.title}</h1>
            <div className="listing-meta">
              <span className="category">{listing.category}</span>
              <span className="location">{listing.location}</span>
            </div>

            <div className="pricing-section">
              <div className="main-price">
                {formatPrice(listing.price)}/{listing.unit}
              </div>
              <div className="minimum-order">
                Minimum Order: {listing.minimum_order} {listing.unit}
              </div>
              <div className="available-quantity">
                Available: {listing.quantity} {listing.unit}
              </div>
            </div>

            {listing.bulkDiscounts?.length > 0 && (
                <div className="bulk-discounts">
                    <h3>Bulk Discounts</h3>
                    <div className="discount-table">
                    <div className="discount-header">
                        <span>Minimum Order</span>
                        <span>Discount</span>
                        <span>Example Savings</span>
                    </div>
                    {listing.bulkDiscounts.map((discount, index) => {
                        const orderTotal = listing.price * discount.quantity;
                        const discountAmount = orderTotal * (discount.discount_percentage / 100);
                        const finalPrice = orderTotal - discountAmount;
                        
                        return (
                        <div key={index} className="discount-row">
                            <span>≥ {discount.quantity} {listing.unit}</span>
                            <span>{discount.discount_percentage}% off total order</span>
                            <span>Save {formatPrice(discountAmount)} on {discount.quantity} {listing.unit}</span>
                        </div>
                        );
                    })}
                    </div>
                </div>
                )}

                {listing.specifications && (
                  <div className="specifications">
                    <h3>Specifications</h3>
                    <div className="specs-table-container">
                      <table className="specs-table">
                        <thead>
                          <tr>
                            {listing.specifications[0]?.headers.map((header, index) => (
                              <th 
                                key={index} 
                                className={listing.specifications[0].rows.every(row => row.bold[index]) ? 'bold' : ''}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {listing.specifications[0]?.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.cells.map((cell, cellIndex) => (
                                <td 
                                  key={cellIndex} 
                                  className={row.bold[cellIndex] ? 'bold' : ''}
                                >
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            <div className="description-section">
              <h3>Product Description</h3>
              <p>{listing.description}</p>
            </div>

            <div className="seller-section">
              <h3>Seller Information</h3>
              <p>Sold by: {listing.seller_name}</p>
            </div>

            {currentUser?.id !== listing.seller_id && (
              <div className="buy-section">
              {currentUser?.id !== listing.seller_id && (
                <button 
                  className={`btn btn-primary buy-now-button ${listing.quantity === 0 ? 'out-of-stock' : ''}`}
                  onClick={() => setShowOrderForm(true)}
                  disabled={listing.quantity === 0}
                >
                  {listing.quantity === 0 ? 'Out of Stock' : 'Buy Now'}
                </button>
              )}
            </div>
            )}
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this listing? This action cannot be undone.</p>
            <div className="modal-actions">
              <button 
                className="btn btn-outline"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </button>
              <button 
                className="btn btn-danger"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Order Form Modal */}
      {showOrderForm && (
        <OrderForm 
          listing={listing}
          onClose={() => setShowOrderForm(false)}
        />
      )}
    </div>
  );
};

export default ListingDetail;