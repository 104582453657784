import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, ShoppingBag } from 'lucide-react';
import { api } from '../services/api';
import ListingCard from './ListingCard';
import './Dashboard.css';

const Dashboard = () => {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
      username: '',
      password: '',
      confirmPassword: ''
    });
    const [error, setError] = useState('');
  
    // Add these new states for notifications
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
  
    // Listings state
    const [listings, setListings] = useState([]);
    const [filteredListings, setFilteredListings] = useState([]);
    const [filters, setFilters] = useState({
      category: '',
      minPrice: '',
      maxPrice: '',
      searchTerm: ''
    });
  
    // Auth check
    useEffect(() => {
      const checkAuth = async () => {
        try {
          const user = await api.verifyToken();
          if (user) {
            setCurrentUser(user);
          }
        } finally {
          setIsLoading(false);
        }
      };
      checkAuth();
    }, []);
  
    // Fetch notifications
    useEffect(() => {
      if (currentUser) {
        fetchNotifications();
        // Poll for new notifications every minute
        const interval = setInterval(fetchNotifications, 60000);
        return () => clearInterval(interval);
      }
    }, [currentUser]); // Make sure to add currentUser as dependency
  
    // Fetch listings
    useEffect(() => {
      const fetchListings = async () => {
        try {
          const data = await api.getListings();
          setListings(data);
          setFilteredListings(data);
        } catch (error) {
          console.error('Error fetching listings:', error);
        }
      };
      fetchListings();
    }, []);
  
    // Apply filters
    useEffect(() => {
      let result = [...listings];
  
      // Apply category filter
      if (filters.category) {
        result = result.filter(listing => listing.category === filters.category);
      }
  
      // Apply price filters
      if (filters.minPrice) {
        result = result.filter(listing => listing.price >= Number(filters.minPrice));
      }
      if (filters.maxPrice) {
        result = result.filter(listing => listing.price <= Number(filters.maxPrice));
      }
  
      // Apply search term
      if (filters.searchTerm) {
        const term = filters.searchTerm.toLowerCase();
        result = result.filter(listing => 
          listing.title.toLowerCase().includes(term) ||
          listing.description.toLowerCase().includes(term)
        );
      }
  
      setFilteredListings(result);
    }, [filters, listings]);

  const fetchNotifications = async () => {
    try {
      const data = await api.getNotifications();
      setNotifications(data);
      setUnreadCount(data.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  
  const handleMarkAsRead = async (notificationId) => {
    try {
      await api.markNotificationAsRead(notificationId);
      setNotifications(notifications.map(n => 
        n.id === notificationId ? { ...n, read: true } : n
      ));
      setUnreadCount(prev => prev - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await api.login(formData.username, formData.password);
      setCurrentUser(user);
      setShowLogin(false);
      setFormData({ username: '', password: '', confirmPassword: '' });
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    try {
      const data = await api.register(formData.username, formData.password);
      // The API already returns a token with the registration response
      // Make sure to store the token
      api.setToken(data.token); // Add this line
      setCurrentUser(data);
      setShowSignup(false);
      setFormData({ username: '', password: '', confirmPassword: '' });
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = () => {
    api.removeToken();
    setCurrentUser(null);
  };

  if (isLoading) {
    return <div className="dashboard loading"></div>;
  }

  return (
    <div className="dashboard">
      {/* Hero Section */}
      <div className="hero">
        <div className="hero-content">
          <div className="hero-left">
            {currentUser && <span>Welcome, {currentUser.username}</span>}
          </div>
          <div className="hero-right">
            {currentUser ? (
              <div className="nav-items">
                <button 
                  className="nav-item" 
                  onClick={() => navigate('/orders')}
                  title="Order History"
                >
                  <ShoppingBag size={20} />
                  <span>Orders</span>
                </button>

                {currentUser.is_seller && (
                  <button 
                    className="nav-item" 
                    onClick={() => navigate('/my-listings')}
                  >
                    My Listings
                  </button>
                )}

                <div className="notifications-container">
                  <button 
                    className="nav-item"
                    onClick={() => setShowNotifications(!showNotifications)}
                    title="Notifications"
                  >
                    <Bell size={20} />
                    {unreadCount > 0 && (
                      <span className="notification-badge">{unreadCount}</span>
                    )}
                  </button>
                  
                  {showNotifications && (
                    <div className="notifications-panel">
                      <div className="notifications-header">
                        <h3>Notifications</h3>
                        {notifications.length > 0 && (
                          <button 
                            className="mark-all-read"
                            onClick={async () => {
                              await api.markAllNotificationsAsRead();
                              setNotifications(notifications.map(n => ({ ...n, read: true })));
                              setUnreadCount(0);
                            }}
                          >
                            Mark all as read
                          </button>
                        )}
                      </div>
                      <div className="notifications-list">
                        {notifications.length > 0 ? (
                          notifications.map(notification => (
                            <div 
                              key={notification.id}
                              className={`notification-item ${!notification.read ? 'unread' : ''}`}
                              onClick={() => handleMarkAsRead(notification.id)}
                            >
                              <div className="notification-content">
                                {notification.message}
                              </div>
                              <div className="notification-time">
                                {new Date(notification.created_at).toLocaleDateString()}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-notifications">
                            No notifications
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {currentUser.is_seller ? (
                  <button className="nav-item create-listing" onClick={() => navigate('/create-listing')}>
                    Create Listing
                  </button>
                ) : (
                  <button className="nav-item become-seller" onClick={() => navigate('/become-seller')}>
                    Become Seller
                  </button>
                )}

                <button className="sign-out-btn" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            ) : (
              <div className="auth-buttons">
                <button 
                  className="btn btn-outline"
                  onClick={() => {
                    setShowLogin(true);
                    setShowSignup(false);
                    setError('');
                  }}
                >
                  Login
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setShowSignup(true);
                    setShowLogin(false);
                    setError('');
                  }}
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Login Modal */}
      {showLogin && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Login</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label>Username</label>
                <input
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="modal-actions">
                <button type="button" className="btn btn-outline" onClick={() => setShowLogin(false)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Signup Modal */}
      {showSignup && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Sign Up</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSignup}>
              <div className="form-group">
                <label>Username</label>
                <input
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="modal-actions">
                <button type="button" className="btn btn-outline" onClick={() => setShowSignup(false)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="main-content">
        <div className="filters-section">
          <input
            type="text"
            name="searchTerm"
            placeholder="Search listings..."
            value={filters.searchTerm}
            onChange={handleFilterChange}
            className="search-input"
          />
          
          <div className="filter-controls">
            <select
              name="category"
              value={filters.category}
              onChange={handleFilterChange}
              className="filter-select"
            >
              <option value="">All Categories</option>
              <option value="metals">Metals</option>
              <option value="plastics">Plastics</option>
              <option value="wood">Wood</option>
              <option value="textiles">Textiles</option>
              <option value="chemicals">Chemicals</option>
              <option value="electronics">Electronics</option>
              <option value="other">Other</option>
            </select>

            <div className="price-filters">
              <input
                type="number"
                name="minPrice"
                placeholder="Min Price"
                value={filters.minPrice}
                onChange={handleFilterChange}
                className="price-input"
              />
              <input
                type="number"
                name="maxPrice"
                placeholder="Max Price"
                value={filters.maxPrice}
                onChange={handleFilterChange}
                className="price-input"
              />
            </div>
          </div>
        </div>

        <div className="listings-grid">
          {filteredListings.length > 0 ? (
            filteredListings.map(listing => (
              <ListingCard key={listing.id} listing={listing} />
            ))
          ) : (
            <div className="no-listings">
              <p>No listings found matching your criteria.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;