import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import { api, BASE_URL } from '../services/api';
import './CreateListing.css';

const CreateListing = ({ isEditing = false, initialData = null, listingId = null }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    quantity: '',
    minimumOrder: '',
    unit: 'kg',
    location: '',
    category: '',
    image: null,
    includeSpecs: false,
    specifications: [{
      headers: ['Property', 'Value', 'Unit'], // Default headers
      rows: [{ cells: ['', '', ''], bold: [false, false, false] }] // Default row
    }],
    bulkDiscounts: [
      { quantity: '', discount: '' }
    ]
  });

// Initialize form with existing data if editing
useEffect(() => {
  if (isEditing && initialData) {
    // Check if specifications actually have content
    const hasValidSpecs = Boolean(
      initialData.specifications && 
      initialData.specifications[0]?.headers?.length > 0 && 
      initialData.specifications[0]?.rows?.some(row => 
        row.cells.some(cell => cell.trim() !== '')
      )
    );

    setFormData({
      title: initialData.title,
      description: initialData.description,
      price: initialData.price,
      quantity: initialData.quantity,
      minimumOrder: initialData.minimumOrder,
      unit: initialData.unit,
      location: initialData.location,
      category: initialData.category,
      image: null,
      includeSpecs: hasValidSpecs,
      specifications: hasValidSpecs ? initialData.specifications : [{
        headers: ['Property', 'Value', 'Unit'],
        rows: [{ cells: ['', '', ''], bold: [false, false, false] }]
      }],
      bulkDiscounts: initialData.bulkDiscounts?.length > 0 
        ? initialData.bulkDiscounts 
        : [{ quantity: '', discount: '' }]
    });

    // Set image preview if there's an existing image
    if (initialData.image_url) {
      setImagePreview(`${BASE_URL}${initialData.image_url}`);
    }
  }
}, [isEditing, initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBulkDiscountChange = (index, field, value) => {
    const newBulkDiscounts = [...formData.bulkDiscounts];
    newBulkDiscounts[index] = {
      ...newBulkDiscounts[index],
      [field]: value
    };
    setFormData(prev => ({
      ...prev,
      bulkDiscounts: newBulkDiscounts
    }));
  };

  const addBulkDiscount = () => {
    setFormData(prev => ({
      ...prev,
      bulkDiscounts: [...prev.bulkDiscounts, { quantity: '', discount: '' }]
    }));
  };

  const removeBulkDiscount = (index) => {
    setFormData(prev => ({
      ...prev,
      bulkDiscounts: prev.bulkDiscounts.filter((_, i) => i !== index)
    }));
  };

  // File handling functions
  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    handleImageFile(file);
  }, []);

  const handleImageFile = (file) => {
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError('Image must be less than 5MB');
      return;
    }

    setFormData(prev => ({
      ...prev,
      image: file
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handleImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const submitData = new FormData();
      
      // Append basic fields
      Object.keys(formData).forEach(key => {
        if (key === 'bulkDiscounts') {
          submitData.append(key, JSON.stringify(formData[key]));
        } else if (key === 'specifications') {
          submitData.append(key, JSON.stringify(formData[key]));
        } else if (key === 'includeSpecs') {
          submitData.append(key, formData[key].toString());
        } else if (key !== 'image') {
          submitData.append(key, formData[key]);
        }
      });
  
      // Append image file if selected
      if (formData.image) {
        submitData.append('image', formData.image);
      }
  
      if (isEditing) {
        await api.updateListing(listingId, submitData);
      } else {
        await api.createListing(submitData);
      }
      
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-listing-page">
      <div className="create-listing-container">
        <h1>{isEditing ? 'Edit Listing' : 'Create New Listing'}</h1>
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleSubmit} className="create-listing-form">
          <div 
            className={`image-upload-section ${isDragging ? 'dragging' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {imagePreview ? (
              <div className="image-preview">
                <img src={imagePreview} alt="Preview" />
                <button 
                  type="button" 
                  className="remove-image"
                  onClick={() => {
                    setImagePreview(null);
                    setFormData(prev => ({ ...prev, image: null }));
                  }}
                >
                  Remove
                </button>
              </div>
            ) : (
              <>
                <div className="upload-prompt">
                  <i className="upload-icon">📁</i>
                  <p>Drag and drop your image here or</p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileInput}
                    id="file-input"
                    className="file-input"
                  />
                  <label htmlFor="file-input" className="file-input-label">
                    Browse Files
                  </label>
                </div>
              </>
            )}
          </div>

          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="e.g., Premium Grade Steel Bars"
            />
          </div>

          <div className="form-group">
            <label>Category</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a category</option>
              <option value="metals">Metals</option>
              <option value="plastics">Plastics</option>
              <option value="wood">Wood</option>
              <option value="textiles">Textiles</option>
              <option value="chemicals">Chemicals</option>
              <option value="electronics">Electronics</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Price per Unit ($)</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                required
                min="0"
                step="0.01"
              />
            </div>

            <div className="form-group">
              <label>Unit</label>
              <select
                name="unit"
                value={formData.unit}
                onChange={handleInputChange}
                required
              >
                <option value="kg">Kilogram (kg)</option>
                <option value="ton">Metric Ton</option>
                <option value="piece">Piece</option>
                <option value="meter">Meter</option>
                <option value="sqm">Square Meter</option>
                <option value="liter">Liter</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Available Quantity</label>
              <input
                type="number"
                name="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                required
                min="0"
              />
            </div>

            <div className="form-group">
              <label>Minimum Order</label>
              <input
                type="number"
                name="minimumOrder"
                value={formData.minimumOrder}
                onChange={handleInputChange}
                required
                min="0"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              required
              placeholder="e.g., Los Angeles, CA"
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              rows="5"
              placeholder="Detailed description of your product..."
            />
          </div>


          <div className="specifications-section">
            <div className="specs-header">
              <h3>Specifications Table</h3>
              <label className="include-specs">
                <input
                  type="checkbox"
                  checked={formData.includeSpecs}
                  onChange={(e) => {
                    setFormData(prev => ({
                      ...prev,
                      includeSpecs: e.target.checked
                    }));
                  }}
                />
                Include specifications table
              </label>
            </div>

            {formData.includeSpecs && (
              <div className="specs-table-builder">
              <div className="table-controls">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => {
                    const newSpecs = [...formData.specifications];
                    newSpecs[0].headers.push(`Column ${newSpecs[0].headers.length + 1}`);
                    newSpecs[0].rows.forEach(row => {
                      row.cells.push('');
                      row.bold.push(false);
                    });
                    setFormData(prev => ({ ...prev, specifications: newSpecs }));
                  }}
                >
                  Add Column
                </button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => {
                    const newSpecs = [...formData.specifications];
                    newSpecs[0].rows.push({
                      cells: new Array(formData.specifications[0].headers.length).fill(''),
                      bold: new Array(formData.specifications[0].headers.length).fill(false)
                    });
                    setFormData(prev => ({ ...prev, specifications: newSpecs }));
                  }}
                >
                  Add Row
                </button>
              </div>
            
              <div className="table-wrapper">
                <table className="specs-table editable">
                  <thead>
                    <tr>
                      {formData.specifications[0].headers.map((header, index) => (
                        <th key={index}>
                        <div className="header-cell">
                          <div
                            className="header-content"
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => {
                              const newSpecs = [...formData.specifications];
                              newSpecs[0].headers[index] = e.target.innerText;
                              setFormData(prev => ({ ...prev, specifications: newSpecs }));
                            }}
                          >
                            {header}
                          </div>
                          {formData.specifications[0].headers.length > 1 && (
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => {
                                const newSpecs = [...formData.specifications];
                                newSpecs[0].headers.splice(index, 1);
                                newSpecs[0].rows.forEach(row => {
                                  row.cells.splice(index, 1);
                                  row.bold.splice(index, 1);
                                });
                                setFormData(prev => ({ ...prev, specifications: newSpecs }));
                              }}
                            >
                              <X size={16} />
                            </button>
                          )}
                        </div>
                      </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formData.specifications[0].rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.cells.map((cell, cellIndex) => (
                          <td 
                            key={cellIndex}
                            className={row.bold[cellIndex] ? 'bold' : ''}
                          >
                            <div className="cell-content">
                              <div
                                contentEditable
                                suppressContentEditableWarning
                                onBlur={(e) => {
                                  const newSpecs = [...formData.specifications];
                                  newSpecs[0].rows[rowIndex].cells[cellIndex] = e.target.innerText;
                                  setFormData(prev => ({ ...prev, specifications: newSpecs }));
                                }}
                              >
                                {cell}
                              </div>
                            </div>
                          </td>
                        ))}
                        {formData.specifications[0].rows.length > 1 && (
                          <td className="row-controls">
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => {
                                const newSpecs = [...formData.specifications];
                                newSpecs[0].rows = newSpecs[0].rows.filter((_, i) => i !== rowIndex);
                                setFormData(prev => ({ ...prev, specifications: newSpecs }));
                              }}
                            >
                              <X size={16} />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            )}
          </div>


          <div className="bulk-discounts-section">
            <h3>Bulk Discounts</h3>
            <p>Add quantity-based discounts for bulk orders</p>
            
            {formData.bulkDiscounts.map((discount, index) => (
              <div key={index} className="bulk-discount-row">
                <div className="form-group">
                  <label>Minimum Quantity</label>
                  <input
                    type="number"
                    value={discount.quantity}
                    onChange={(e) => handleBulkDiscountChange(index, 'quantity', e.target.value)}
                    min="0"
                  />
                </div>
                <div className="form-group">
                  <label>Discount (%)</label>
                  <input
                    type="number"
                    value={discount.discount}
                    onChange={(e) => handleBulkDiscountChange(index, 'discount', e.target.value)}
                    min="0"
                    max="100"
                  />
                </div>
                {index > 0 && (
                  <button
                    type="button"
                    className="btn btn-outline remove-discount"
                    onClick={() => removeBulkDiscount(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            
            <button
              type="button"
              className="btn btn-outline add-discount"
              onClick={addBulkDiscount}
            >
              Add Bulk Discount
            </button>
          </div>

          <div className="form-actions">
            <button type="button" className="btn btn-outline" onClick={() => navigate('/')}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? (isEditing ? 'Saving...' : 'Creating...') : (isEditing ? 'Save Changes' : 'Create Listing')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateListing;